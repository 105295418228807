<template>
  <div class="shilut-db">
    <div class="shilut-db-header">
        <h1 v-if="!is_choose">ניהול שילוט</h1>
        <h1 v-if="is_choose && !is_barcodes">בחירת שלט</h1>
        <h1 v-if="is_choose && is_barcodes">בחירת ברקודים</h1>
    </div>
    <div class="shilut-db-tools">
        <el-button v-if="!is_choose" @click="show_new_sign=true" style="margin-right:5px;" type="success">פריט חדש</el-button>
        <el-select style="margin-right:5px;" v-model="selected_department" class="m-2" placeholder="מחלקה" size="large">
            <el-option
            v-for="department in departments"
            :key="department.uid"
            :value="department.name"
            />
        </el-select>
        <el-input  style="width:30%; margin-right:5px;" v-model="search" placeholder="חיפוש" />
        <el-button v-if="!is_choose && role>=99" @click="show_add_department=true" style="margin-right:5px;" type="primary">הוסף מחלקה</el-button>
       
        <el-button v-if="is_choose && is_barcodes" @click="handle_reload_barcodes"  style="margin-right:5px;" type="danger">עדכון ברקודים</el-button>

        <el-button @click="handle_reload_from_excel" v-if="!is_choose" style="margin-right:auto;" type="success">טעינה מאקסל</el-button>
        <input ref="upload" v-show="false" type="file" @change="handle_import_from_csv">
        <div v-if="is_choose" class="back">
            <i @click="$emit('back')" class="material-icons">arrow_back</i>
        </div>
    </div>
    <div class="shilut-db-content">
        <table>
            <tr>
                <th style="cursor:pointer;" @click="handleSortTable('ברקוד', !selectedSorted.order)">ברקוד</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחלקה', !selectedSorted.order)">מחלקה</th>
                <th style="cursor:pointer;" @click="handleSortTable('עברית', !selectedSorted.order)">שם פריט עברית</th>
                <th style="cursor:pointer;" @click="handleSortTable('לועזי', !selectedSorted.order)">שם פריט לועזי</th>
                <th style="cursor:pointer;" @click="handleSortTable('מחיר', !selectedSorted.order)">מחיר</th>
                <th>יחידת מידה</th>
                <th>פעולות</th>
            </tr>
            <template v-for="item in sorted_list" :key="item.barcode">
                <tr>
                   <td>{{item.barcode}}</td> 
                   <td>{{item.department}}</td> 
                   <td>{{item.he_name}}</td> 
                   <td>{{item.ru_name}}</td> 
                   <td>{{item.price}} &#8362;</td> 
                   <td>{{item.size}} <span v-if="item.size=='יח'">'</span></td>
                   <td v-if="!is_choose">
                     <i @click="handle_edit(item)"  class="material-icons edit-icon icon">edit</i>
                     <i @click="handle_delete(item)"  class="material-icons delete-icon icon">delete</i>
                   </td> 
                   <td v-if="is_choose">
                     <el-button v-if="!is_barcodes" @click="handle_choose(item)" type="success">בחר</el-button>
                     <Checkbox v-if="is_barcodes" :value="item.barcode" v-model="barcodes_choosen" />
                   </td> 
                </tr>
            </template>   
        </table>
    </div>
    <div class="shilut-db-pagination">
        <el-pagination 
             v-model:currentPage="pagination.current_page"
             background 
            :page-size="pagination.number_per_page"
            layout="prev,pager,next"
            :total="filter_by_search.length" 
        />
    </div>
   
    <CreateNewSign v-if="show_new_sign" @close="show_new_sign=false; selected_item=null"
    :edit_item="selected_item"
    @finish="handle_finish" @finish_edit="handle_finish_edit"/>

    <ExcelDataPreview @close="show_excel_data_preview=false; selected_excel_data=[];" 
    @submit="handle_submit_excel_data"
    v-if="show_excel_data_preview" :data="selected_excel_data" />

    <AddDepartment @close="show_add_department=false" :departments="departments" v-if="show_add_department" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {get_all_signs_from_db,delete_item_from_db,check_if_item_exist
,create_new_sign,user,get_all_departments,role} from './Scripts/scripts'
import { computed, defineAsyncComponent } from '@vue/runtime-core'
import {alert_confirm,slide_pop_error,alert} from '../../../Methods/Msgs'
import Swal from 'sweetalert2'
import XLSX from "xlsx";
import Checkbox from 'primevue/checkbox';


export default {
    props:['is_choose','is_barcodes'],
    emits:['item_choosen','back','barcodes'],
    components:{
        ExcelDataPreview: defineAsyncComponent(() => import('./components/ExcelDataPreview.vue')),
        AddDepartment: defineAsyncComponent(() => import('./components/AddDepartment.vue')),
        CreateNewSign: defineAsyncComponent(() => import('./CreateNewSign.vue')),
        Checkbox
    },
    setup(_,{emit}){
    
        const barcodes_choosen = ref([])
        const show_add_department = ref(false)
        const departments = ref([])
        const selected_department = ref('')
        const upload = ref()
        const search = ref('')
        const show_new_sign = ref(false)
        const selected_item = ref(null)
        const items = ref([])
        const selected_excel_data = ref([])
        const show_excel_data_preview = ref(false)

        const pagination = ref({
            current_page:1,
            number_per_page:30,
        })

        const handle_reload_barcodes = ()=>{
            if(barcodes_choosen.value.length > 0){
                emit('barcodes',barcodes_choosen.value)
            }
        }
        
        const handle_reload_from_excel = () =>{
            Swal.fire({
                title: 'לידיעתך!',
                text: 'עליך לטעון קובץ אקסל לפי השדות שבתמונה',
                imageUrl: 'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/excel%20example_375x375.jpg?alt=media&token=aaaa7dbc-448e-4665-b8d7-bb5f85e99010',
                imageHeight: 200,
                imageWidth: 450,
                confirmButtonText: 'המשך',
                cancelButtonText:'ביטול',
                showCancelButton: true,
            })
            .then((res)=>{
                if(res.isConfirmed) upload.value.click()
            })
        }

         //טעינת פריטים מקובץ אקסל
        const handle_import_from_csv = (e)=>{
          var file = e.target.files[0];
          if (!file) return;
          var FR = new FileReader();
            FR.onload = async function(e) {
              var data = new Uint8Array(e.target.result);
              var workbook = XLSX.read(data, {type: 'array'});
              var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
              
              // header: 1 instructs xlsx to create an 'array of arrays'
              var result = XLSX.utils.sheet_to_json(firstSheet, { raw: false });
              
              // data preview
                selected_excel_data.value = result
                show_excel_data_preview.value = true
                upload.value.value=''
            };
            FR.readAsArrayBuffer(file);
        }

        const handle_submit_excel_data = async()=>{
            try{
                const promises = []
                for(const item of selected_excel_data.value){
                  if(Object.keys(item).length<6) throw new Error(`הפריט ${item['ברקוד']} לא הוזן כראוי בקובץ אקסל!`)
                  for (const [key, value] of Object.entries(item)){
                     switch (key) {
                        case 'ברקוד':
                            const res =  await check_if_item_exist(value)
                            if(res) throw new Error(`הפריט ${value} כבר קיים במערכת!`)
                            break;
                        case 'שם עברית':
                            if(!value) throw new Error('חסר שם עברי!')
                            break;
                        case 'שם לועזית':
                            if(!value) throw new Error('חסר שם לועזי!')
                            break;
                        case 'מחיר קטלוגי':
                            if(!value) throw new Error('חסר מחיר קטלוגי!')
                            break;
                        case 'מחלקה':
                            const index = departments.value.findIndex(dep=>dep.name == value)
                            if(index==-1) throw new Error(`המחלקה ${value} לא קיימת!`)
                            break;
                        case 'מידה':
                            if(!value) throw new Error('חסר מידה!')
                            break;
                        default:
                            throw new Error('אחד מהשדות בקובץ אקסל לא תואם למה שנדרש!')      
                    }
                  }
                }

                for(const item of selected_excel_data.value){
                        const obj = {
                            barcode:item['ברקוד'],
                            date_added:new Date(),
                            date_updated:new Date(),
                            first_number:'',
                            second_number:'',
                            he_name:item['שם עברית'],
                            ru_name:item['שם לועזית'],
                            size:item['מידה'],
                            price:item['מחיר קטלוגי'],
                            department: item['מחלקה'],
                            volume:'',
                            user_update:user.value.uid
                        }
                        items.value.push(obj)
                        promises.push(await create_new_sign(obj))
                }
    
                Promise.all(promises).then(()=>{
                    alert('success','הצלחה','הפריטים נטענו בהצלחה')
                    .then(()=>{
                        selected_excel_data.value = []
                        show_excel_data_preview.value = false
                    }) 
                })
            }catch(err){
                console.error(err.message)
                slide_pop_error(err.message)
                show_excel_data_preview.value = false
            }
        }

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const slice_list = ref(computed(()=>{
            return filter_by_search.value.slice((pagination.value.current_page -1 ) * pagination.value.number_per_page,
            ((pagination.value.current_page-1) * pagination.value.number_per_page) + pagination.value.number_per_page)
        }))

        const sorted_list = ref(computed(() => {
            if (selectedSorted.value.name == "")return slice_list.value;
            if (selectedSorted.value.name == "ברקוד") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.barcode > b.barcode
                        ? 1
                        : b.barcode  > a.barcode
                        ? -1
                        : 0;
                    }
                    return a.barcode > b.barcode
                    ? -1
                    : b.barcode  > a.barcode
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחלקה") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.department > b.department
                        ? 1
                        : b.department  > a.department
                        ? -1
                        : 0;
                    }
                    return a.department > b.department
                    ? -1
                    : b.department  > a.department
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "עברית") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.he_name > b.he_name
                        ? 1
                        : b.he_name  > a.he_name
                        ? -1
                        : 0;
                    }
                    return a.he_name > b.he_name
                    ? -1
                    : b.he_name  > a.he_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "לועזי") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.ru_name > b.ru_name
                        ? 1
                        : b.ru_name  > a.ru_name
                        ? -1
                        : 0;
                    }
                    return a.ru_name > b.ru_name
                    ? -1
                    : b.ru_name  > a.ru_name
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחיר") {
                return slice_list.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a.price > b.price
                        ? 1
                        : b.price  > a.price
                        ? -1
                        : 0;
                    }
                    return a.price > b.price
                    ? -1
                    : b.price  > a.price
                    ? 1
                    : 0;
                });
            }
        }));
        
        const filter_by_search = ref(computed(() => {
            if(!search.value) return filter_by_department.value
            return filter_by_department.value.filter(item=>{
                if(item.barcode.includes(search.value)) return item
                if(item.he_name.includes(search.value)) return item
                if(item.ru_name.includes(search.value)) return item
                if(item.price == search.value) return item
            })
        }))

        const filter_by_department = ref(computed(()=>{
            if(selected_department.value== '' || selected_department.value == 'הכל')
            return items.value
            return items.value.filter(item=>item.department == selected_department.value)
        }))
        
        const handle_choose = (item) => {
            emit('item_choosen',item)
        }

        const handle_edit = (item) => {
            selected_item.value = JSON.parse(JSON.stringify(item))
            show_new_sign.value = true
        }

        const handle_delete = (item) => {
            alert_confirm(`האם למחוק את המוצר ${item.barcode}?`)
            .then(async res=>{
                if(res.isConfirmed){
                    await delete_item_from_db(item.barcode)
                    items.value = items.value.filter(_item=>_item.barcode!=item.barcode)
                }
            })
        }
        
        const handle_finish = (item) => {
            items.value.push(item)
            selected_item.value = null
            show_new_sign.value = false
        }

        const handle_finish_edit = (item) => {
            const index = items.value.findIndex(_item => _item.barcode == item.barcode)
            if(index!=-1){
                items.value[index] = item
            }
            selected_item.value = null
            show_new_sign.value = false
        }

        const init = async()=>{
           items.value = await get_all_signs_from_db()
           departments.value = [{name:'הכל',uid:'asdfasg3276478326'},...await get_all_departments()]
        }

        init()
        return{
            handle_finish,
            handle_delete,
            handleSortTable,
            handle_edit,
            handle_finish_edit,
            handle_choose,
            handle_import_from_csv,
            handle_reload_from_excel,
            search,
            show_new_sign,
            items,
            pagination,
            filter_by_search,
            selectedSorted,
            sorted_list,
            slice_list,
            selected_item,
            upload,
            selected_excel_data,
            show_excel_data_preview,
            handle_submit_excel_data,
            departments,
            selected_department,
            show_add_department,
            role,
            barcodes_choosen,
            handle_reload_barcodes,
        }
    }
}
</script>

<style scoped>
    .shilut-db{
        margin: 0 auto;
        width: 100%;
        height: 100%;
        max-width: 1200px;
        padding: 5px;
        color: #fff;
    }
    .shilut-db-header{
        width: 100%;
        height: 50px;
        background: var(--secondary);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .shilut-db-tools{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .back{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--danger);
        color: #fff;
        cursor: pointer;
        margin-right: auto;
    }
    .shilut-db-content{
        width: 100%;
        height: calc(100% - 150px);
        overflow: hidden;
        overflow-y: auto;
    }
    .shilut-db-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }
    .icon{
        cursor: pointer;
    }
    .delete-icon{
        color: var(--danger);
        margin: 0 5px;
    }
    .edit-icon{
        color: var(--warning);
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--success);
        color: #fff;
        
    }
</style>